<template>
  <div class="space-y-2">
    <GoogleLogin
      label="auth.checkout.methods.googleChat"
      @click="onOAuthLoginClicked(OauthProvider.Google)"
    />
    <FacebookLogin
      label="auth.checkout.methods.facebookChat"
      @click="onOAuthLoginClicked(OauthProvider.Facebook)"
    />
  </div>
</template>

<script lang="ts" setup>
import {OauthProvider} from "../../../enums/oauthProvider";
import FacebookLogin from "./buttons/FacebookLogin.vue";
import GoogleLogin from "./buttons/GoogleLogin.vue";

const { isLoading, loginFlow, createOAuthLoginFlow } = useLogin();

async function onOAuthLoginClicked(provider: OauthProvider) {
  try {
    const route = useRoute();
    localStorage.setItem('requestedPath', route.fullPath);
    localStorage.setItem('message-modal', true);
    window.location = await createOAuthLoginFlow(provider);
  } catch (e) {
    // console.log(e);
  }
}
</script>

<style scoped>

</style>
